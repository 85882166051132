import React from "react"
import PropTypes from "prop-types"

const Header = ({ siteTitle }) => {

  return (
  <header>
    <span>{siteTitle}</span>
    <span>Las Brisas, Zicatela, Puerto Escondido, México</span>
  </header>

  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
